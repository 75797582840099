import { Streamfield, WagtailImage } from '@/types';
import BannerImage from '@components/BannerImage';
import Streamfields from '@streamfields/Streamfields';
import { BasePageProps, basePageWrap } from '../BasePage';

type Props = {
  content: Streamfield[];
  banner_image: WagtailImage;
} & BasePageProps;

function HomePage(props: Props) {
  const { content, title, banner_image: bannerImage } = props;

  return (
    <div className='home-page'>
      <BannerImage image={bannerImage} />
      <h1 className='container'>{title}</h1>
      {content ? <Streamfields fields={content} /> : null}
    </div>
  );
}

export default basePageWrap<Props>(HomePage);
