import { WagtailImage } from '@/types';
import { addBaseUrl } from '@/utils/url';
import Image from 'next/image';

type Props = {
  image: WagtailImage;
};

function BannerImage(props: Props) {
  const { image } = props;

  return (
    <Image
      className='banner-image'
      src={addBaseUrl(image.url)}
      alt={image.alt}
      width={image.width}
      height={image.height}
      priority
    />
  );
}

export default BannerImage;
